import { Container } from "react-bootstrap"
import { IoCamera } from "react-icons/io5";
import { Card } from "react-bootstrap";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useEffect, useRef, useState, useCallback, HTMLInputTypeAttribute } from "react";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import axios from "axios";
import { toast } from "react-toastify";
import { getSessionData } from "../../../utils/storageUtils";
import Quagga, { QuaggaJSResultObject } from '@ericblade/quagga2'; // ES6
import { useNavigate } from "react-router-dom"
import { FaGlasses } from "react-icons/fa";
import { speak } from "../../../utils/notifySons"

function Enderecar() {

    //Variaveis de estado para controlar a aplicação
    const [info, setInfo] = useState<any>()
    // const [code, setCode] = useState<any>()
    const [local, setLocal] = useState<any>()
    const [localAtual, setLocalAtual] = useState<any>()
    const [localPicking, setLocalPicking] = useState<any>()
    const [localSugestao, setLocalSugestao] = useState<any>()
    const [depositante, setDepositante] = useState<any>()
    const [descricao, setDescricao] = useState<any>()
    const [volume, setVolume] = useState<any>()
    const [validade, setValidade] = useState<any>()
    const [visivel, setVisivel] = useState(false)
    const [mostralModalLoading, setMostraModalLoading] = useState(false)
    const [valorInput, setValorInput] = useState<any>()
    const [editavel, setEditavel] = useState(true)
    const [botao, setBotao] = useState(true)
    const [viewBotao, setViewBotao] = useState(true)
    const [botaoVisivel, setBotaoVisivel] = useState<boolean>()
    const [idLocalBotao, setIdLocalBotao] = useState<any>()
    // const [cameraStatus, setCameraStatus] = useState(true)
    const [focoBotao, setFocoBotao] = useState(false)

    //Referencias para controlar visibilidade 
    const inputUA = useRef<HTMLInputElement>(null);
    const inputIDlocal = useRef<HTMLInputElement>(null);
    const botaoRef = useRef(null);

    //Obtendo informações do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")
    const navigate = useNavigate()

    function handleCameraClick() { }

    //Consulta UA para setar outros campos
    async function consultaUA() {
        setMostraModalLoading(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/consultar/UA?UA=${valorInput}&idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta: any) {
            //Seta campos da UA com base no retorno
            setViewBotao(false)
            setLocalAtual(resposta.data[0].ConcatenatedValues)
            setDepositante(resposta.data[0].Depositante)
            setDescricao(resposta.data[0].Descricao)
            setVolume(resposta.data[0].Volume)
            const validade = resposta.data[0].Validade
            setValidade(validade.substring(0, 10))
            setMostraModalLoading(false)
        }).catch(function (erro) {
            setViewBotao(true)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                if (status == 406) {
                    toast.error(erro.response.data)
                    speak(erro.response.data)
                    setValorInput("")
                    setLocalAtual("")
                    setLocalPicking("")
                    setDepositante("")
                    setDescricao("")
                    setVolume("")
                    setValidade("")
                    inputUA.current?.focus()
                }
                setMostraModalLoading(false)
            }
        })
    }

    //Função para consultar local de picking e setar campo
    function consultaLocalPicking() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/local/picking?UA=${valorInput}&idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setLocalPicking(resposta.data[0].ConcatenatedValues)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.warn(erro.response.data)
            }
        })
    }

    //Função para verificar id local e preencher outros campos
    async function verificaIdLocal() {
        setMostraModalLoading(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/idLocal/${info}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta: any) {
            //Seta as informações com base no retorno
            setLocal(resposta.data[0].ConcatenatedValues)
            setEditavel(false)
            setBotao(false)
            setMostraModalLoading(false)
            setIdLocalBotao(resposta.data[0].ID)
            inputIDlocal.current?.blur()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                speak(erro.response.data.message)
                setBotao(true)
                setMostraModalLoading(false)
            }
        })
    }

    // function consultaLocalSugestao() {
    //     axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/local/sugestao?idFilial=${idFilial}`, {
    //         headers: {
    //             Authorization: token
    //         }
    //     }).then(function (resposta: any) {

    //     }).catch(function (erro) {

    //     })
    // }

    //Função para botão endereçar e realizar todas verificações
    function enderecar(idLocalBtn: any = idLocalBotao) {
        setMostraModalLoading(true)
        //Verifica informação que será enviada no local de picking
        const localPickingEnvio = localPicking !== undefined ? localPicking : " "
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/enderecar/${idLocalBtn}/${usuarioLogado}?UA=${valorInput}&localPicking=${localPickingEnvio}&idFilial=${idFilial}`, null, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {
            //No retorno reseta todos os campos
            setMostraModalLoading(false)
            toast.success(resposta.data)
            speak(resposta.data)
            // setVisivel(true)
            setValorInput("")
            setLocalAtual("")
            setLocal("")
            setLocalPicking("")
            setInfo("")
            setDepositante("")
            setDescricao("")
            setVolume("")
            setValidade("")

        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setMostraModalLoading(false)
                if (erro.response.status == 406) {
                    toast.error(erro.response.data.message)
                    speak(erro.response.data.message)
                }
            }
        })
    }

    ///Funçao para controlar visibilidade do botão
    function visualizarBotao() {
        if (botao == false && viewBotao == false) {
            setBotaoVisivel(false)
        } else {
            setBotaoVisivel(true)
        }
    }

    //useEffect para rodar visualizarBotao e setar visibilidade
    useEffect(() => {
        visualizarBotao()
    }, [botao, viewBotao])

    return (
        <>
            <Card className="mt-2" style={{ position: 'relative', overflowY: 'auto' }}>
                <Card.Body>
                    <div hidden={botaoVisivel} style={{
                        border: '4px solid orange',
                        borderRadius: '50%',
                        padding: '10px',
                        display: 'inline-block',
                        position: 'fixed',
                        bottom: '90px',
                        right: '20px'
                    }}>
                        <a
                            ref={botaoRef} id="botao"
                            onClick={() => {
                                enderecar();
                                inputUA.current?.focus()
                            }}
                            style={{ color: focoBotao ? 'blue' : 'orange', cursor: 'pointer', transition: 'color 0.3s' }}
                        >
                            <CheckRoundedIcon style={{ color: 'orange' }} />
                        </a>
                    </div>
                    <div className="text-center" hidden={true} id="reader"></div>
                    <div className="mt-2">
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div className="d-flex align-items-center">
                                <label htmlFor="U.A." style={{ minWidth: '80px' }}>U.A.</label>
                                <input
                                    type="number"
                                    ref={inputUA}
                                    value={valorInput}
                                    autoFocus
                                    id="U.A."
                                    name="U.A."
                                    step="any"
                                    className="me-2 w-75"
                                    style={{ height: '30px' }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            setValorInput(parseInt(inputUA.current?.value || '0'));
                                            //current é usado em uma referencia para acessar o elemento referenciado
                                            const target = event.target as HTMLInputElement;
                                            if (target.value == "" || target.value == null) {
                                                toast.error("Valor de U.A. não pode ser 0")
                                                speak("Valor de U.A. não pode ser 0")
                                                setValorInput("")
                                                setLocalAtual("")
                                                setLocalPicking("")
                                                setDepositante("")
                                                setDescricao("")
                                                setVolume("")
                                                setValidade("")
                                            } else {
                                                if (inputIDlocal.current) {
                                                    inputIDlocal.current.focus();
                                                }
                                            }
                                        }
                                    }}
                                    onChange={(event) => {
                                        setValorInput(event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        consultaUA()
                                        consultaLocalPicking()
                                        setLocal("")
                                        setEditavel(true)
                                        setInfo("")
                                        setLocalPicking("")
                                        setBotao(true)
                                        setValorInput(event.target.value)
                                    }}
                                />
                                <button type="button" style={{ visibility: 'hidden' }} onClick={handleCameraClick}><IoCamera /></button>
                            </div>
                            <div className="d-flex align-items-center" hidden={visivel}>
                                <label htmlFor="IDLocal" style={{ minWidth: '80px' }}>ID Local</label>
                                <input
                                    ref={inputIDlocal}
                                    value={info}
                                    type="text"
                                    id="IDLocal"
                                    name="IDLocal"
                                    className="me-2 w-75"
                                    style={{ height: '30px' }}
                                    onKeyDown={(event) => {

                                        if (event.key === 'Enter') {
                                            event.preventDefault()
                                            const target = event.target as HTMLInputElement;
                                            // console.log(target.value)
                                            if (target.value == "" || target.value == null) {
                                                toast.error("O ID do local não pode ser 0")
                                                speak("O ID do local não pode ser 0")
                                                setLocal("")
                                            } else {
                                                verificaIdLocal()
                                            }
                                        }
                                    }}
                                    onChange={(event) => {
                                        if (event.target.value != "") {
                                            const value = event.target.value;
                                            const valueTratado = value.replace(/[-_\\|[\]{}=;?/]/g, '');
                                            if (value !== valueTratado) {
                                                toast("Caracteres especiais não são permitidos.");
                                                speak("Caracteres especiais não são permitidos.")
                                                event.target.value = valueTratado;
                                            } else {
                                                setInfo(valueTratado)
                                            }
                                        } else {
                                            setInfo(event.target.value)
                                        }

                                    }}
                                    onBlur={() => {
                                        verificaIdLocal()
                                    }}
                                />
                                <button type="button" style={{ visibility: 'hidden' }} onClick={handleCameraClick}><IoCamera /></button>
                            </div>
                            <div className="d-flex align-items-center" hidden={visivel}>
                                <label htmlFor="Local Sugestao" style={{ minWidth: '80px', maxWidth: '80px' }}>Local Sugestão</label>
                                <input type="text" id="Local Sugestao" name="Local Sugestao" step="any" className="me-2 w-75" disabled style={{ height: '30px' }} />
                                <button type="button" style={{ visibility: 'hidden' }}><IoCamera /></button>
                            </div>
                            <div className="d-flex align-items-center" hidden={visivel}>
                                <label htmlFor="Local" style={{ minWidth: '80px' }}>Local</label>
                                <input
                                    type="text"
                                    id="Local"
                                    name="Local"
                                    step="any"
                                    className="me-2 w-75"
                                    style={{ height: '30px' }}
                                    value={local}
                                    readOnly={!editavel}
                                />
                                <button type="button" style={{ visibility: 'hidden' }}><IoCamera /></button>
                            </div>
                            <div className="d-flex align-items-center" hidden={visivel}>
                                <label htmlFor="Local Atual" style={{ minWidth: '80px' }}>Local Atual</label>
                                <input type="text" id="Local Atual" name="Local Atual" step="any" className="me-2 w-75" disabled style={{ height: '30px' }} value={localAtual} />
                                <button type="button" style={{ visibility: 'hidden' }}><IoCamera /></button>
                            </div>
                            <div className="d-flex align-items-center" hidden={visivel}>
                                <label htmlFor="Local Picking" style={{ minWidth: '80px', maxWidth: '80px' }}>Local Picking</label>
                                <input type="text" id="Local Picking" name="Local Picking" step="any" className="me-2 w-75" disabled style={{ height: '30px' }} value={localPicking} />
                                <button type="button" style={{ visibility: 'hidden' }}><IoCamera /></button>
                            </div>
                            <div className="d-flex align-items-center" hidden={visivel}>
                                <label htmlFor="Depositante" style={{ minWidth: '80px' }}>Depositante</label>
                                <input type="text" id="Depositante" name="Depositante" step="any" className="me-2 w-75" disabled style={{ height: '30px' }} value={depositante} />
                                <button type="button" style={{ visibility: 'hidden' }}><IoCamera /></button>
                            </div>
                            <div className="d-flex align-items-center" hidden={visivel}>
                                <label htmlFor="Descricao" style={{ minWidth: '80px' }}>Descrição</label>
                                <input type="text" id="Descricao" name="Descricao" step="any" className="me-2 w-75" disabled style={{ height: '30px' }} value={descricao} />
                                <button type="button" style={{ visibility: 'hidden' }}><IoCamera /></button>
                            </div>
                            <div className="d-flex align-items-center" hidden={visivel}>
                                <label htmlFor="Volume" style={{ minWidth: '80px' }}>Volume</label>
                                <input type="text" id="Volume" name="Volume" step="any" className="me-2 w-75" disabled style={{ height: '30px' }} value={volume} />
                                <button type="button" style={{ visibility: 'hidden' }}><IoCamera /></button>
                            </div>
                            <div className="d-flex align-items-center" hidden={visivel}>
                                <label htmlFor="Validade" style={{ minWidth: '80px' }}>Validade</label>
                                <input type="text" id="Validade" name="Validade" step="any" className="me-2 w-75" disabled style={{ height: '30px' }} value={validade} />
                                <button type="button" style={{ visibility: 'hidden' }}><IoCamera /></button>
                            </div>

                        </div>
                    </div>
                </Card.Body>
            </Card>

            <ModalLoading show={mostralModalLoading} />
        </>
    )
}

export default Enderecar 