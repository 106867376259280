import { Card, Row } from "react-bootstrap"
import { IoMdArrowBack } from "react-icons/io"
import { BsBoxSeam } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios";
import { getSessionData } from "../../../utils/storageUtils";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import ModalPickingPadrao from "./components/ModalPickingPadrao";
import ModalPickingVarOP from "./components/ModalPickingVarOP";
import ModalPickingVarBal from "./components/ModalPickingVarBal";

function PedidoPicking() {
    //Variáveis para controle do componente funcional
    const [depositante, setDepositante] = useState<any>()
    const [data, setData] = useState<any>()
    const [idPedido, setIdPedido] = useState<any>()
    const [nomePedido, setNomePedido] = useState<any>()
    const [codigo, setCodigo] = useState<any>([])
    const [codigoSelecionado, setCodigoSelecionado] = useState<any>()
    const [descricao, setDescricao] = useState<any>()
    const [qtdePedido, setQtdePedido] = useState<any>()
    const [unManuseio, setUnManuseio] = useState<any>()
    const [viewInputs, setViewInputs] = useState<any>(true)
    const [viewValidade, setViewValidade] = useState(true)
    const [viewLote, setViewLote] = useState(true)
    const [viewLocalPicking, setViewLocalPicking] = useState(true)
    const [localPicking, setLocalPicking] = useState<any>()
    const [WMS, setWMS] = useState<any>()
    const [localUA, setLocalUA] = useState<any>()
    const [UASugerida, setUASugerida] = useState<any>()
    const [saldo, setSaldo] = useState<any>()
    const [validadeUA, setValidadeUA] = useState<any>()
    const [validade, setValidade] = useState<any>()
    const [un, setUn] = useState<any>()
    const [lote, setLote] = useState<any>()
    const [mostraModalPadrao, setMostraModalPadrao] = useState(false)
    const [mostraModalVarOP, setMostraModalVarOP] = useState(false)
    const [mostraModalVarBalanca, setMostraModalVarBalanca] = useState(false)
    const [qtdeSeparado, setQtdeSeparado] = useState<any>()
    const [CNPJ, setCNPJ] = useState<any>()
    const [pedidoCliente, setPedidoCliente] = useState<any>()

    //Instanciando useNavigate e useParams
    const navigate = useNavigate()
    const params = useParams()

    const btnRef = useRef(null)

    const pedido = params.pedido

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")

    //Função para carregar informações baseado no pedido
    async function carregaInfos() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/infos/${pedido}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            // console.log(resposta)
            setPedidoCliente(resposta.data[0].PEDIDO_CLIENTE)
            setDepositante(resposta.data[0].Fantasia)
            setData(resposta.data[0].DATA)
            setIdPedido(resposta.data[0].ID_PEDIDO)
            setNomePedido(resposta.data[0].ROTA)
            //Tendo carregado as informações, carrega codigo através da fantasia e do id pedido
            carregaCodigo(resposta.data[0].Fantasia, resposta.data[0].ID_PEDIDO)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.info(erro.response.data)
            }
        })
    }

    //Função para carregar códigos com base no depositante e id do pedido
    async function carregaCodigo(depositante: any, idPedido: any) {

        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/codigo/${depositante}/${idPedido}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setCNPJ(resposta.data.CNPJ)
            setCodigo(resposta.data.codigos)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                console.info(erro.response.data)
            }
        })
    }

    //Função para carrega descrição
    function carregaDescricao(codigoSelecionado: any) {
        if (codigoSelecionado == "Selecione") {
            return;
        }

        //Busca id wms através do código selecionado
        const codSelecioando = codigo.find((cod: any) => cod.Código == codigoSelecionado)

        const idWMS = codSelecioando ? codSelecioando.WMS : '';

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/descricao/${idWMS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDescricao(resposta.data[0].DESCRICAO)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                console.info(erro.response.data)
            }
        })
    }

    //Função para carregar quantidade do pedido com base no código selecionado
    function carregaQtdePedido(codigoSelecionado: any) {
        if (codigoSelecionado == "Selecione") {
            return;
        }

        //Busca qtdePedido através do código selecionado
        const qtdPedidoNovo = codigo.find((qtdPed: any) => qtdPed.Código == codigoSelecionado)
        setQtdePedido(qtdPedidoNovo ? qtdPedidoNovo.QtdePedido : '')
    }

    //Função para carregar o ID WMS
    function carregaWMS(codigoSelecionado: any) {
        if (codigoSelecionado == "Selecione") {
            return;
        }

        //Buscando id wms com base no código selecionado
        const WMSNovo = codigo.find((i: any) => i.Código == codigoSelecionado)
        setWMS(WMSNovo ? WMSNovo.WMS : '')
    }

    //Função para carregar informações do código 
    function carregaInfosDoCodigo(codigoSelecionado: any) {
        if (codigoSelecionado == "Selecione") {
            setViewInputs(true)
            setViewLocalPicking(true)
            setViewLote(true)
            setViewValidade(true)
            setDescricao("")
            setQtdePedido("")
            setWMS("")
            return;
        }

        //Buscando o cod selecioando no array de codigos
        const codSelecioando = codigo.find((cod: any) => cod.Código == codigoSelecionado)

        //Aproveitando o cod selecionado para buscar o WMS que será enviado
        const idWMS = codSelecioando ? codSelecioando.WMS : '';

        //Fazendo um split para desmonstar a data e remontá-la no formato yyyy/mm/dd
        let [day, month, year] = data.split('/');
        let formattedDate = `${year}-${month}-${day}`;

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/infos/codigo/${idWMS}/${idPedido}/${formattedDate}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data.validadeVisivel == 1) {
                setViewLote(true)
                setViewValidade(false)
                setValidade(resposta.data.validadeOuLote)
            } else if (resposta.data.loteVisivel == 1) {
                setViewValidade(true)
                setViewLote(false)
                setLote(resposta.data.validadeOuLote)
            } else {
                setViewValidade(true)
                setViewLote(true)
            }

            if (resposta.data.viewInputs == 0) {
                setViewInputs(true)
                if (resposta.data.localPicking && resposta.data.localPicking[0].Endereco) {
                    setViewLocalPicking(false)
                    setLocalPicking(resposta.data.localPicking[0].Endereco)
                } else {
                    setViewLocalPicking(true)
                    setLocalPicking("")
                }
            } else {
                setViewLocalPicking(true)
                setViewInputs(false)
                setLocalUA(resposta.data.localUA)
                setUASugerida(resposta.data.infos[0].PALLET)
                setSaldo(resposta.data.infos[0].SALDO)
                setValidadeUA(resposta.data.validadeUA)
                setValidade(resposta.data.validadeOuLote)
            }
        }).catch(function (erro) {
            console.log(erro)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    //Função para realizar a separação
    function separar() {
        //Verifica se informações cruciais estão preenchidas
        if (!pedido ||
            !depositante ||
            !WMS) {
            toast.info("Selecione um código para realizar a separação")
            return;
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/separar?idFilial=${idFilial}`, {
            // idPedido,
            pedido,
            depositante,
            WMS,
            data,
            // qtdePedido,
            // descricao,
            // lote,
            validade
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            // console.log(resposta)
            setUn(resposta.data.unidade)
            setQtdeSeparado(resposta.data.qtdSeparado)
            //Com base no tipo de peso seta a visibilidade dos modais
            if (resposta.data.tipoPeso == "VARIADO_OPTICO" || resposta.data.tipoPeso == "VAR_ROM_MANUAL") {
                setMostraModalVarOP(true)
                setMostraModalPadrao(false)
                setMostraModalVarBalanca(false)
            } else if (resposta.data.tipoPeso == "PADRÃO") {
                setMostraModalPadrao(true)
                setMostraModalVarOP(false)
                setMostraModalVarBalanca(false)
            } else {
                setMostraModalVarBalanca(true)
                setMostraModalPadrao(false)
                setMostraModalVarOP(false)
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    //Função para fechar modal e resetar campos
    function fecharModal() {
        setMostraModalPadrao(false)
        setMostraModalVarOP(false)
        setMostraModalVarBalanca(false)

        setCodigoSelecionado("")
        setDescricao("")
        setQtdePedido("")
        setLocalUA("")
        setLocalPicking("")
        setWMS("")
        setUASugerida("")
        setSaldo("")
        setValidadeUA("")
        setValidade("")
        setLote("")
        setViewInputs(true)
        setViewLocalPicking(true)
        setViewLote(true)
        setViewValidade(true)

        carregaCodigo(depositante, idPedido)
    }

    //Roda a função ao carregar a tela
    useEffect(() => {
        carregaInfos()
    }, [])

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    return (
        <>
            <Card className="mt-2">
                <Card.Header>
                    <Row className="mt-2">
                        <div className="col-2 mt-1">
                            <IoMdArrowBack
                                style={{ color: 'DarkOrange' }}
                                size={28}
                                onClick={() => navigate("/menu/picking")}
                            />
                        </div>

                        <div className="col-6 justify-content-end d-flex ms-auto">
                            <button
                                type="button"
                                className="btn btn-novo fs-4 rounded"
                                ref={btnRef}
                                onClick={separar}
                            // ref={btnDesfragmentarRef}
                            >
                                <span className='me-2'>Separar</span><BsBoxSeam size={20} />
                            </button>
                        </div>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row >
                        <div className="col form-floating">
                            <select
                                autoFocus
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                // onKeyDown={(event) => handleKeyDown(event, odoresRef)}
                                value={codigoSelecionado}
                                onKeyDown={(event) => handleKeyDown(event, btnRef)}
                                onChange={(event) => {
                                    setCodigoSelecionado(event.target.value)
                                    carregaDescricao(event.target.value)
                                    carregaQtdePedido(event.target.value)
                                    carregaWMS(event.target.value)
                                    carregaInfosDoCodigo(event.target.value)
                                }}
                            >
                                <option>Selecione</option>
                                {/* <option>CÓDIGO  |  QTDE. PEDIDO</option> */}
                                {codigo.map((cod: any) => (
                                    <option key={cod.WMS}>{cod.Código}</option>
                                ))}
                            </select>
                            <label className="form-label">CÓDIGO</label>
                        </div>

                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1">
                            <input
                                type="text"
                                value={descricao}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">DESCRIÇÃO</label>
                        </div>

                        <div className="col-5 form-floating mt-1">
                            <input
                                // type="text"
                                value={qtdePedido}
                                className="form-control bordasInferiorInput text-black"
                                style={{ textAlign: 'right' }}
                                disabled
                            />
                            <label className="form-label">QTDE. DO PEDIDO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1" hidden={viewLocalPicking}>
                            <input
                                // type="text"
                                value={localPicking}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">LOCAL DE PICKING</label>
                        </div>

                        <div className="col-7 form-floating mt-1" hidden={viewInputs}>
                            <input
                                type="text"
                                value={localUA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">LOCAL U.A</label>
                        </div>

                        <div className="col-5 form-floating mt-1" hidden={false}>
                            <input
                                // type="text"
                                value={WMS}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">WMS</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1" hidden={viewInputs}>
                            <input
                                // type="text"
                                value={UASugerida}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">U.A SUGERIDA</label>
                        </div>

                        <div className="col-5 form-floating mt-1" hidden={viewInputs}>
                            <input
                                // type="text"
                                value={saldo}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">SALDO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1" hidden={viewInputs}>
                            <input
                                // type="text"
                                value={validadeUA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">VALIDADE DE U.A</label>
                        </div>

                        {/* <div className="col-5 form-floating mt-1" hidden={viewInputs}>
                            <input
                                // type="text"
                                value={un}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">UN</label>
                        </div> */}

                        <div className="col-5 form-floating mt-1" hidden={viewValidade}>
                            <input
                                // type="text"
                                value={validade}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">VALIDADE</label>
                        </div>

                        <div className="col-5 form-floating mt-1" hidden={viewLote}>
                            <input
                                // type="text"
                                value={lote}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">LOTE</label>
                        </div>
                    </Row>

                    {/* <Row>
                        <div className="form-floating mt-1" hidden={viewValidade}>
                            <input
                                // type="text"
                                value={validade}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">VALIDADE</label>
                        </div>

                        <div className="form-floating mt-1" hidden={viewLote}>
                            <input
                                // type="text"
                                value={lote}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">LOTE</label>
                        </div>
                    </Row> */}

                    <Row className="mt-3 border border-primary"></Row>


                    <Row>
                        <div className="col form-floating mt-3">
                            <input
                                type="text"
                                value={depositante}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">DEPOSITANTE</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1">
                            <input
                                value={data}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">DATA DE CARGA</label>
                        </div>

                        <div className="col-5 form-floating mt-1">
                            <input
                                type="number"
                                value={idPedido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">ID PEDIDO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col form-floating mt-1">
                            <input
                                // type="text"
                                value={nomePedido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">PEDIDO</label>
                        </div>
                    </Row>

                </Card.Body>
                {/* 
                <Card.Footer>
                    
                </Card.Footer> */}
            </Card>

            <ModalPickingPadrao
                isOpen={mostraModalPadrao}
                fecharModal={fecharModal}
                pedido={pedido}
                codigo={codigoSelecionado}
                descricao={descricao}
                qtdePedido={qtdePedido}
                minValidade={validade}
                // qtdeSeparado={qtdeSeparado}
                CNPJ={CNPJ}
                dataCarga={data}
                lote={lote}
                WMSPedido={WMS}
                usuarioLogado={usuarioLogado}
                pedidoCliente={pedidoCliente}
                idPedido={idPedido}
                unidade={un}
                idFilial={idFilial}
                token={token}
            />

            <ModalPickingVarOP
                isOpen={mostraModalVarOP}
                fecharModal={fecharModal}
                pedido={pedido}
                codigo={codigoSelecionado}
                descricao={descricao}
                qtdePedido={qtdePedido}
                minValidade={validade}
                // qtdeSeparado={qtdeSeparado}
                CNPJ={CNPJ}
                WMS={WMS}
                lote={lote}
                dataCarga={data}
                pedidoCliente={pedidoCliente}
                token={token}
                idFilial={idFilial}
            />

            <ModalPickingVarBal
                isOpen={mostraModalVarBalanca}
                fecharModal={fecharModal}
                pedido={pedido}
                codigo={codigoSelecionado}
                descricao={descricao}
                qtdePedido={qtdePedido}
                minValidade={validade}
                qtdeSeparado={qtdeSeparado}
                dataCarga={data}
                CNPJ={CNPJ}
                WMS={WMS}
                lote={lote}
                idPedido={idPedido}
                pedidoCliente={pedidoCliente}
                idFilial={idFilial}
                token={token}
            />
        </>
    )
}

export default PedidoPicking