import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { toast } from "react-toastify";

//Propriedades a receber
interface ModalVariadoBalancaProps {
    isOpen: boolean;
    fecharModal: () => void;
    qtdeTransferir: any;
    setPesoBruto: React.Dispatch<React.SetStateAction<any>>;
    setPesoLiquido: React.Dispatch<React.SetStateAction<any>>;
    pesoLiquido: any;
    pesoEmbMultiplicado: any;
}

const ModalVariadoBalanca: React.FC<ModalVariadoBalancaProps> = ({
    isOpen,
    fecharModal,
    qtdeTransferir,
    setPesoBruto,
    setPesoLiquido,
    pesoLiquido,
    pesoEmbMultiplicado
}) => {

    const btnSalvarRef = useRef(null)

    //Ao salvar verifica se peso líquido é válido e set o peso bruto
    function handleClickSalvar() {
        if (pesoLiquido == 0 || pesoLiquido == "" || pesoLiquido == undefined) {
            toast.info("Insira um peso líquido válido para salvar")
        } else {
            setPesoBruto(pesoLiquido + pesoEmbMultiplicado)
            fecharModal()
        }
    }

    //Função para controlar o foco de elementos
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            handleClickSalvar()
        }
    };

    return (
        <>
            <Modal className="mt-3" show={isOpen}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title >Romanear</Modal.Title>
                </Modal.Header >
                <Modal.Body className="bg-white">

                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                autoFocus
                                value={pesoLiquido}
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setPesoLiquido(parseFloat(event.target.value))
                                }}
                                onKeyDown={(event) => handleKeyDown(event)}
                            // ref={codigoRef}
                            />
                            <label className="form-label">PESO LÍQUIDO TOTAL </label>
                        </div>
                    </div>

                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                value={qtdeTransferir}
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                disabled
                            // onKeyDown={(event) => handleKeyDown(event, volumesATransferirRef)}
                            />
                            <label className="form-label">VOLUMES A TRANSFERIR </label>
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div>
                        <button
                            type="button"
                            className="me-2 btn btn-danger rounded"
                            onClick={() => {
                                fecharModal()
                                setPesoLiquido("")
                            }}
                        >
                            Cancelar
                        </button>

                        <button
                            type="button"
                            className="btn btn-novo"
                            onClick={() => {
                                handleClickSalvar()
                            }}
                            ref={btnSalvarRef}
                        >
                            <span className='me-1'><FaSave size={15} /></span>Salvar
                        </button>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalVariadoBalanca