import axios from "axios";
import { useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getSessionData } from "../../../../utils/storageUtils";
import { useNavigate } from "react-router-dom";

interface ModalConsultaPalletProps {
    isOpen: boolean;
    fecharModal: () => void;
}

const ModalConsultaPallet: React.FC<ModalConsultaPalletProps> = ({
    isOpen,
    fecharModal
}) => {
    //Variáveis de estado para controle do componenete funcional
    const [UA, setUA] = useState<any>()
    const [depositante, setDepositante] = useState<any>()
    const [codigo, setCodigo] = useState<any>()
    const [descricao, setDescricao] = useState<any>()
    const [qtde, setQtde] = useState<any>()
    const [qtdeProg, setQtdeProg] = useState<any>()
    const [validade, setValidade] = useState<any>()
    const [lote, setLote] = useState<any>()
    const [endereco, setEndereco] = useState<any>()
    const [pesoLiq, setPesoLiq] = useState<any>()

    const navigate = useNavigate();

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")

    //Função para consultar informações UA
    function consultaUA() {
        if (!UA) {
            toast.info("Insira uma U.A. válida")
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/acoes/consulta/UA/${UA}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            //Prenche variaveis com todas as informações retornadass
            setDepositante(resposta.data[0].Depositante)
            setCodigo(resposta.data[0].CODIGO)
            setDescricao(resposta.data[0].Descricao)
            setQtde(resposta.data[0].QUANTIDADE)
            setQtdeProg(resposta.data[0].EMPENHO)
            const validade = resposta.data[0].VALIDADE
            setValidade(validade.substring(0, 10))
            const pesoLiquido = resposta.data[0].PL
            setPesoLiq(pesoLiquido.toFixed(2).replace('.', ','))
            setLote(resposta.data[0].LOTE)
            setEndereco(resposta.data[0].Endereco)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                if (status == 406) {
                    //Caso tenha algum erro, reseta a interface
                    toast.error(erro.response.data)
                    setUA("")
                    setDepositante("")
                    setCodigo("")
                    setDescricao("")
                    setQtde("")
                    setPesoLiq("")
                    setQtdeProg("")
                    setValidade("")
                    setLote("")
                    setEndereco("")
                }
            }
        })
    }

    return (
        <>
            <Modal
                show={isOpen}
                className='mt-3'
                onShow={() => {
                    setUA("")
                    setDepositante("")
                    setCodigo("")
                    setDescricao("")
                    setQtde("")
                    setQtdeProg("")
                    setPesoLiq("")
                    setValidade("")
                    setLote("")
                    setEndereco("")
                }}
            >
                <Modal.Header className="bg-white d-flex justify-content-between align-items-center" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center' }}>{"Consultar U.A."}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">

                    <div className="col form-floating mt-2">
                        <input
                            type="number"
                            autoFocus
                            value={UA}
                            className="form-control bordasInferiorInput text-black"
                            onChange={(event) => {
                                setUA(event.target.value)
                            }}
                            onBlur={() => consultaUA()}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    consultaUA()
                                }
                            }}
                        />
                        <label className="form-label">U.A.</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="text"
                            value={depositante}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">DEPOSITANTE</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            // type="number"
                            value={codigo}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">CÓDIGO</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="text"
                            value={descricao}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">DESCRIÇÃO</label>
                    </div>

                    <Row className="mt-4 border border-primary"></Row>

                    <Row>
                        <div className="col form-floating mt-2">
                            <input
                                // type="text"
                                value={qtde}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">QTDE</label>
                        </div>

                        <div className="col form-floating mt-2">
                            <input
                                // type="text"
                                value={qtdeProg}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">QTDE. PROG</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col form-floating mt-2">
                            <input
                                // type="text"
                                value={lote}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">LOTE</label>
                        </div>

                        <div className="col form-floating mt-2">
                            <input
                                // type="text"
                                value={pesoLiq}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">PESO LÍQ</label>
                        </div>
                    </Row>

                    <div className="col form-floating mt-2">
                        <input
                            // type="text"
                            value={validade}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">VALIDADE</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            // type="text"
                            value={endereco}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">ENDEREÇO</label>
                    </div>

                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="mb-2 d-flex justify-content-center w-100">
                        <button
                            type="button"
                            className="btn btn-cancelar me-2 w-50"
                            data-dismiss="modal"
                            onClick={() => {
                                fecharModal()
                            }}
                        >
                            Voltar
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ModalConsultaPallet