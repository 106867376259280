import { mdiContentSaveCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { speak } from "../../../../utils/notifySons";

//Propriedades necessárias para modal
interface ModalBalancaProps {
    isOpen: boolean,
    fecharModal: () => void;
    qtde: number;
    acao: (pesoEmbTotal: number) => void;
}

const ModalBalanca: React.FC<ModalBalancaProps> = ({
    isOpen,
    fecharModal,
    qtde,
    acao
}) => {
    const [pesoEmbTotal, setPesoEmbTotal] = useState<number>(0)

    return (
        <>
            <Modal className='mt-5' show={isOpen} onShow={() => setPesoEmbTotal(0)}>
                <Modal.Header className="bg-white">
                    <Modal.Title>{`Insira o peso total das ${qtde} embalagens`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className='row align-items-end justify-content-center'>
                        <div className="col-10 form-floating mt-1">
                            <input
                                type="number"
                                autoFocus
                                value={pesoEmbTotal}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(event) => setPesoEmbTotal(parseFloat(event.target.value))}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        if (!pesoEmbTotal) {
                                            toast.info("Preencha o peso total das embalagens para prosseguir")
                                            speak("Preencha o peso total das embalagens para prosseguir")
                                        } else {
                                            acao(pesoEmbTotal)
                                            fecharModal()
                                        }
                                    }
                                }}
                            />
                            <label className="form-label">Peso total</label>
                        </div>
                        <div className='col-10 mt-3'>
                            <button
                                type="button"
                                className='mb-2 w-100 text-center btn btn-novo btn-block rounded'
                                onClick={() => {
                                    if (!pesoEmbTotal) {
                                        toast.info("Preencha o peso total das embalagens para prosseguir")
                                        speak("Preencha o peso total das embalagens para prosseguir")
                                    } else {
                                        acao(pesoEmbTotal)
                                        fecharModal()
                                    }
                                }}
                            >
                                <span className='me-1'>Gravar</span><Icon path={mdiContentSaveCheck} size={0.8} />
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <button
                        type="button"
                        className="btn btn-outline-secondary btn-sm"
                        onClick={fecharModal}
                    >
                        Cancelar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalBalanca